<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-row :gutter="48">
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button style="float: right;margin-bottom: 10px;" @click="goAddArticle">添加文章</a-button>
          </span>
        </a-col>
      </a-row>
    </div>
    <a-table :columns="columns" :dataSource="tableData" :loading="loading.data" rowKey="id">
      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="type" slot-scope="text">
        {{ text | typeFilter }}
      </span>

      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goEditArticle(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该新闻?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '关键字',
    dataIndex: 'keywords'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'NewsList',
  data() {
    return {
      description: '公告列表页面',
      loading: {
        search: false,
        data: false
      },
      columns,
      tableData: [],
      cates: [],
      ModalText: '确定删除？'
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '发布中',
        0: '编辑中'
      }
      return statusMap[status]
    },
    typeFilter(status) {
      const typeMap = {
        'all': '所有',
        'user': '用户',
        'teacher': '教员'
      }
      return typeMap[status]
    }
  },
  created() {
    this.loadData()
  },
  computed: {
  },
  methods: {
    loadData(parameter) {
      this.loading.data = true
      this.$http({
        url: '/admin/article/list',
        params: parameter
      }).then(res => {
        if (res.status === 'success') {
          console.log(res)
          this.tableData = res.data.list
          this.cates = res.data.cates
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      }).finally(() => {
        this.loading.data = false
      })
    },
    goAddArticle() {
      this.$router.push({ 'name': 'ArticleEdit' })
    },
    goEditArticle(record) {
      this.$router.push({ 'name': 'ArticleEdit', query: { id: record.id } })
    },
    onDelete(id) {
      this.$http({
        url: '/admin/article/delete',
        method: 'post',
        data: { 'id': id }
      }).then(res => {
        if (res.status === 'success') {
          // 删除成功
          this.$notification.success({
            message: res.status,
            description: res.message
          })
          this.loadData()
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      })
    }
  }
}
</script>
